import Link from 'next/link';
import s from './Navbar.module.css';
import Logo from 'components/icons/Logo';
import { useRouter } from 'next/router';
import { useUser } from 'utils/useUser';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import PricingModal from 'components/Modal';
import { Fragment, useEffect, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { Dialog, Popover, Transition } from '@headlessui/react';
import {
  HomeIcon,
  HomeModernIcon,
  PaintBrushIcon,
  ShoppingCartIcon
} from '@heroicons/react/24/outline';
import { useTranslation, Trans } from 'next-i18next';
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon
} from '@heroicons/react/20/solid';
// const products = [
//   { name: 'Real Estate', description: 'Show your listings like never before', href: '/use-cases/real-estate', icon: HomeIcon },
//   { name: 'Home Makeovers', description: 'Visualize your renovation like never before ', href: '/use-cases/home-makeover', icon: HomeModernIcon },
//   { name: 'Interior Designers', description: 'Understand your client faster than ever', href: '/use-cases/interior-designers', icon: PaintBrushIcon },
//   { name: 'Furniture Staging', description: 'Show your products in any setting', href: '/use-cases/furniture-staging', icon: ShoppingCartIcon },

// ]

const prodIcons = [HomeIcon, HomeModernIcon, PaintBrushIcon, ShoppingCartIcon];

const callsToAction = [
  { name: 'Free Trial', href: '/', icon: PlayCircleIcon },
  { name: 'Contact Us', href: '/contact', icon: PhoneIcon }
];

const Navbar = ({ translator }) => {
  // console.log('locale', locale);
  const router = useRouter();
  // const { t } = useTranslation('common');
  const supabaseClient = useSupabaseClient();
  const { subscription, user } = useUser();
  const [openModal, setOpenModal] = useState(false);

  const products = translator('usecases', { returnObjects: true });

  // if products is an array return producs ifnot empty list

  // ensure that products is an array

  const isArray = (a) => {
    return !!a && a.constructor === Array;
  };

  const a = isArray(products) ? products : [];

  return (
    <nav
      className={classnames(s.root, {
        'bg-white': router.pathname === '/',
        'bg-white': router.pathname !== '/'
      })}
    >
      <PricingModal
        passOpenModal={setOpenModal}
        openModal={openModal}
        translator={translator}
      ></PricingModal>
      <a href="#skip" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <div className="mx-auto max-w-7xl px-2 py-1 sm:px-6 ">
        <div className="flex justify-between align-center flex-row py-3 relative">
          <div className="flex items-center justify-start">
            <a className={s.logo} aria-label="Logo" href="/">
              <Logo />
            </a>

            <nav className="space-x-1 lg:space-x-3 md:ml-52 grid grid-cols-4">
              {/* <div className="hidden lg:block">
                <Popover className="relative">
                  <Popover.Button className={s.link}>
                    {translator('navuses')}
                    <ChevronDownIcon
                      className="h-5 w-5 flex-none "
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className=" absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {a &&
                          a?.map((item, i) => (
                            <div
                              key={item.name}
                              className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                            >
                              <div className="flex-auto">
                                <a
                                  href={item.href}
                                  className="block font-semibold "
                                >
                                  {item.name}
                                  <span className="absolute inset-0" />
                                </a>
                                <p className="mt-1 text-gray-600">
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                        {callsToAction.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon
                              className="h-5 w-5 flex-none text-gray-400"
                              aria-hidden="true"
                            />
                            {translator(item.name)}
                          </a>
                        ))}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div> */}

              <Link href="/pricing" className="hidden lg:block">
                <span className={s.link}>{translator('navpricing')}</span>
              </Link>

              <Link href="/account" className="hidden lg:block">
                <span className={s.link}>{translator('navmyaccount')}</span>
              </Link>

              <Link href="/contact" className="hidden lg:block">
                <span className={s.link}>{translator('navcontact')}</span>
              </Link>
            </nav>
          </div>

          <div className="flex flex-1 justify-end space-x-2 md:space-x-8">
            {!user ? (
              //<Bars3Icon className='text-[#4138C2] stroke-2 mr-4' height={33}></Bars3Icon>
              <nav>
                {/* <Link href='/roast-my-room'>
                <button className='bg-indigo-600 px-3 py-1 text-white border-2 border-slate-200 font-bold rounded-lg text-xs whitespace-nowrap '>
                  Room Advice
                </button>               
                </Link> */}
                <a href="/account">
                  <button className="bg-none px-3 py-1 text-slate-400 border-2 border-slate-200 font-bold rounded-lg text-xs whitespace-nowrap ">
                    {translator('navmyaccount')}
                  </button>
                </a>
              </nav>
            ) : (
              <nav>
                {/* <Link href='/roast-my-room'>
                <button className='bg-indigo-600 px-3 py-1 text-white border-2 border-slate-200 font-bold rounded-lg text-xs whitespace-nowrap  '>
                  Room Advice
                </button> 

                </Link> */}

                {subscription ? (
                  <a href="/account">
                    <button className="bg-none px-3 py-1 text-slate-400 border-2 border-slate-200 font-bold rounded-lg text-xs whitespace-nowrap ">
                      {translator('navmyaccount')}
                    </button>
                  </a>
                ) : (
                  <button
                    className="bg-gradient-to-r mr-3 from-[#3864FF] to-[#F1148B] px-2 py-1.5 text-xs text-white font-bold rounded-md shadow-sm"
                    onClick={() => setOpenModal(true)}
                  >
                    {translator('navupgrade')}
                  </button>
                )}
              </nav>
            )}
          </div>
          {/* <PricingModal passOpenModal={setOpenModal} openModal={openModal} products={products}></PricingModal> */}

          {/* <div className="flex flex-1 justify-end space-x-8">
            {user ? (
              <span
                className={s.link}
                onClick={async () => {
                  await supabaseClient.auth.signOutranslator();
                  router.push('/signin');
                }}
              >
                Sign out
              </span>
            ) : (
              <Link href="/signin">
                <a className={s.link}>Sign in</a>
              </Link>
            )}
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
