import cn from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Button from 'components/ui/Button';
import { postData } from 'utils/helpers';
import { getStripe } from 'utils/stripe-client';
import { useUser } from 'utils/useUser';
import { getActiveProductsWithPrices } from '@/utils/supabase-client';
import { trackEvent } from 'utils/analytics';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function Pricing({ openModal, hideTeams, translator }) {
  const apiPlans = [
    { id: 1, quantity: 1, name: '500 images/month', unavailable: false },
    { id: 2, quantity: 2, name: '1,500 images/month', unavailable: false },
    { id: 3, quantity: 4, name: '3,000 images/month', unavailable: false },
    { id: 4, quantity: 8, name: '6,000 images/month', unavailable: false },
    { id: 5, quantity: 13, name: '10,000 images/month', unavailable: false }
  ];

  const [apiPlan, setApiPlan] = useState(apiPlans[0]);

  function MyListbox({ apiPlan, setApiPlan }) {
    return (
      <Listbox value={apiPlan} onChange={setApiPlan}>
        {({ open }) => (
          <div>
            {/* <Listbox.Label className="block text-xs font-medium text-gray-700">Assigned to</Listbox.Label> */}
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default bg-white rounded-2xl border border-slate-300 font-medium py-2.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-black text-sm">
                <span className="inline-flex w-full truncate">
                  <span className="truncate">{apiPlan.name}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-[#4138C2]"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                // as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                  {apiPlans.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-500' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <div>
                          <div className="flex flex-col md:flex-row">
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'truncate'
                              )}
                            >
                              {item.name}
                            </span>

                            {item.featured_new ? (
                              <span className="absolute right-4 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                                New
                              </span>
                            ) : null}

                            {item.pro_feature && !subscription && (
                              <span className="absolute right-4 inline-flex items-center rounded bg-indigo-700 px-2 font-bold py-0.5 text-xs font-medium text-white">
                                Pro
                              </span>
                            )}
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    );
  }
  const router = useRouter();
  const [billingInterval, setBillingInterval] = useState('year');
  const [products, setProducts] = useState([
    { name: 'SnapLuna Pro', description: 'Unlimited renders' }
  ]);
  const [priceIdLoading, setPriceIdLoading] = useState();
  const { user, isLoading, subscription } = useUser();

  useEffect(() => {
    const getShopifyProducts = async () => {
      const response = await getActiveProductsWithPrices();

      // filter with no prices

      const filtered = response.filter((product) => {
        return product.prices.length > 0;
      });

      const sorted = filtered.sort((a, b) => {
        return a.prices[0].unit_amount - b.prices[0].unit_amount;
      });

      setProducts(sorted);
    };
    getShopifyProducts();
  }, []);

  function getClientReferenceId() {
    // ignore ts error

    return (
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + new Date().getTime()
    );
  }

  const handleCheckout = async (price, quantity) => {
    // get the client reference id

    const clientReferenceId = getClientReferenceId();

    // console.log('referral_code', clientReferenceId);

    setPriceIdLoading(price.id);

    let checkoutRoute = '/api/create-checkout-session';

    if (!user) {
      // checkoutRoute = '/api/create-checkout-session-express'
      if (openModal) {
        openModal(false);
      }
      return router.push({ pathname: '/signin', query: 'pro=true' });
    }
    if (subscription) {
      return router.push('/account');
    }

    try {
      const { sessionId } = await postData({
        url: checkoutRoute,
        data: {
          price,
          clientReferenceId: clientReferenceId,
          quantity: quantity
        }
      });

      trackEvent('checkout-stripe', {
        event_category: 'conversion',
        event_label: 'checkout',
        value: price.unit_amount
      });

      const stripe = await getStripe();
      stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      return alert(error?.message);
    } finally {
      setPriceIdLoading(undefined);
    }
  };

  const perksBasic = translator('perks_basic', { returnObjects: true });
  const perksPro = translator('perks_pro', { returnObjects: true });
  const perksEnterprise = translator('perks_enterprise', {
    returnObjects: true
  });

  const isArray = (a) => {
    return !!a && a.constructor === Array;
  };

  const planData = {
    'SnapLuna Standard': {
      monthly: 'Unlimited',
      perks: isArray(perksPro) ? perksPro : []
    },
    'SnapLuna Professional': {
      monthly: 'Unlimited (web)',
      perks: isArray(perksPro) ? perksEnterprise : []
    },
    'SnapLuna Basic': {
      monthly: 50,
      perks: isArray(perksBasic) ? perksBasic : []
    },
    'SnapLuna Enterprise': {
      monthly: 300,
      perks: isArray(perksEnterprise) ? perksEnterprise : []
    }
  };

  // console.log(planData);

  if (!products?.length)
    return (
      <section className="flex justify-center">
        <div
          role="status"
          className="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-100 rounded shadow animate-pulse dark:divide-gray-100 md:p-6 dark:border-gray-100"
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
            </div>
            <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
            </div>
            <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
            </div>
            <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
            </div>
            <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
            </div>
            <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </section>
    );

  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto py-0 sm:py-0">
        <div className="mb-0 sm:flex sm:flex-col sm:align-center ">
          <div className="relative self-center mt-6 bg-neutral-100 rounded-3xl p-0.5 flex sm:mt-8 ">
            <button
              onClick={() => setBillingInterval('month')}
              type="button"
              className={`${
                billingInterval === 'month'
                  ? 'relative w-1/2 bg-white border-indigo-800 shadow-sm text-zing-800 '
                  : 'ml-0.5 relative w-1/2 border border-transparent text-slate-600'
              } rounded-3xl m-1 py-1 sm:py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8`}
            >
              {translator('monthly')}
            </button>
            <button
              onClick={() => setBillingInterval('year')}
              type="button"
              className={`${
                billingInterval === 'year'
                  ? 'relative w-1/2 bg-white border-indigo-800 shadow-sm text-zing-800 '
                  : 'ml-0.5 relative w-1/2 border border-transparent text-slate-600'
              } rounded-3xl m-1 py-1 sm:py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8`}
            >
              {translator('yearly')}
            </button>
          </div>
        </div>
        <div className="mt-4 space-y-4 sm:mt-6 sm:space-y-0 sm:grid md:grid-cols-3 sm:gap-6 lg:max-w-7xl grid-cols-1">
          {products.map((product, indexx) => {
            const price = product?.prices?.find(
              (price) => price.interval === billingInterval
            );
            if (!price) return null;
            const priceString = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: price.currency,
              minimumFractionDigits: 0
            }).format(Math.round((price?.unit_amount / 12.0 || 0) / 100));
            const truePriceString = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: price.currency,
              minimumFractionDigits: 0
            }).format((price?.unit_amount || 0) / 100);

            const apiPriceString = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: price.currency,
              minimumFractionDigits: 0
            }).format(
              Math.round(
                ((price?.unit_amount * apiPlan.quantity) / 12.0 || 0) / 100
              )
            );

            const trueApiPriceString = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: price.currency,
              minimumFractionDigits: 0
            }).format((price?.unit_amount * apiPlan.quantity || 0) / 100);

            const quantity =
              product.name == 'Snapluna Professional' ? apiPlan.quantity : 1;

            return (
              <div
                key={product.id}
                className={cn(
                  'rounded-3xl divide-y divide-indigo-600 bg-neutral-100 shadow border border-neutral-100  text-slate-700 relative',
                  {
                    'border border-pink-500': subscription
                      ? product.name === subscription?.prices?.products?.name
                      : product.name === 'Freelancer'
                  },
                  { 'border-pink-400 border-[2px] shadow-2xl ': indexx === 1 }
                )}
              >
                <div className="">
                  <div className="px-4 py-4 bg-white rounded-t-3xl">
                    <h3
                      className="text-lg font-bold leading-8 tracking-tight text-indigo-600"
                      id={product.id}
                    >
                      {/* remove SnapLuna  */}
                    </h3>
                    <div className="px-4 py-1 bg-neutral-100 rounded-lg justify-center items-center inline-flex">
                      <div className="text-center text-transparent bg-clip-text bg-gradient-to-r from-[#3864FF] to-[#F1148B]  text-base font-bold ">
                        {product.name.replace('SnapLuna ', '') + ' Plan'}
                      </div>
                    </div>
                    <p className="text-base  text-slate-600">
                      {/* {product.description} */}
                    </p>
                    <div className="mt-4">
                      <span className="text-3xl font-bold tracking-tight text-gray-900">
                        {billingInterval == 'year'
                          ? product.name == 'SnapLuna Professional'
                            ? apiPriceString
                            : priceString
                          : product.name == 'SnapLuna Professional'
                          ? trueApiPriceString
                          : truePriceString}
                      </span>

                      <span className="text-md font-semibold leading-8 tracking-normal text-gray-500 ml-1">
                        /{'month'}
                        {billingInterval === 'year' && (
                          <div className="text-zinc-500 text-xs">
                            {translator('save_over')}
                          </div>
                        )}
                      </span>
                      <br />
                      <span className="text-zinc-500 text-[10px] font-extrabold">
                        {translator('7 day money-back guarantee')}
                      </span>
                      {billingInterval === 'year' && (
                        <div className="text-[10px] font-light italic">
                          billed as {truePriceString} yearly{' '}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" bg-neutral-100 px-4 py-2 rounded-b-3xl">
                    {/* <p className="font-bold mt-4">
                      {translator('This Includes')}
                    </p> */}

                    {product.name == 'SnapLuna Professional' && (
                      <div className="flex flex-row gap-2 items-center">
                        <span>API Access:</span>{' '}
                        <MyListbox apiPlan={apiPlan} setApiPlan={setApiPlan} />
                      </div>
                    )}

                    {
                      <ul>
                        <li
                          className="flex flex-row mb-2 mt-2 sm:mb-3 sm:mt-3 text-sm"
                          key={'sssss'}
                        >
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0,0,256,256"
                              width="20px"
                              height="20px"
                              fillRule="nonzero"
                            >
                              <g
                                fill="#4f45e4"
                                fillRule="nonzero"
                                stroke="none"
                                strokeWidth="1"
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                strokeMiterlimit="10"
                                strokeDasharray=""
                                strokeDashoffset="0"
                                fontFamily="none"
                                fontWeight="none"
                                fontSize="none"
                                textAnchor="none"
                              >
                                <g transform="scale(8.53333,8.53333)">
                                  <path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path>
                                </g>
                              </g>
                            </svg>{' '}
                          </span>
                          <span className="font-extrabold">
                            {planData[product.name]['monthly'] + ' '}
                            <span className="font-normal">
                              {' designs per month'}
                            </span>
                          </span>
                        </li>
                        {planData[product.name]['perks'].map((item, index) => {
                          return (
                            <li
                              className={`flex flex-row mb-2 mt-2 sm:mb-3 sm:mt-3 text-sm ${
                                index > 3 && 'hidden'
                              }`}
                              key={item}
                              show={index < 1}
                            >
                              <span className="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0,0,256,256"
                                  width="20px"
                                  height="20px"
                                  fillRule="nonzero"
                                >
                                  <g
                                    fill="#4f45e4"
                                    fillRule="nonzero"
                                    stroke="none"
                                    strokeWidth="1"
                                    strokeLinecap="butt"
                                    strokeLinejoin="miter"
                                    strokeMiterlimit="10"
                                    strokeDasharray=""
                                    strokeDashoffset="0"
                                    fontFamily="none"
                                    fontWeight="none"
                                    fontSize="none"
                                    textAnchor="none"
                                  >
                                    <g transform="scale(8.53333,8.53333)">
                                      <path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path>
                                    </g>
                                  </g>
                                </svg>{' '}
                              </span>
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    }

                    {/* <p>API Access: 500 images a month</p> */}
                    <Button
                      variant="newone"
                      type="button"
                      disabled={isLoading}
                      loading={priceIdLoading === price.id}
                      onClick={() => handleCheckout(price, quantity)}
                      className="mt-4 block w-full rounded-lg py-2 text-md font-semibold text-black text-center bg-[#4138C2] hover:bg-indigo-900"
                    >
                      {product.name === subscription?.prices?.products?.name
                        ? translator('pricing_manage')
                        : translator('pricing_subscribe')}
                    </Button>
                  </div>

                  {/* <div className="flex flex-row gap-2 items-center mt-5 justify-center">
                    <svg
                      stroke="#24cc65"
                      fill="#24cc65"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                    </svg>
                    <p className="">Secured payment by</p>
                    <img
                      alt="stripe"
                      src="https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/ed88e3f0-8c79-420c-4d7c-c8c313b50500/free"
                      className=""
                    />
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center mt-2">
          <img
            alt="Payment Secured by Stripe"
            src="https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/1a1b01a8-146e-4225-f54d-1ab4b314bd00/free"
            className="mt-1 max-h-44  "
          ></img>
        </div>

        <div className=" text-center">
          <p className="m-3 text-lg">
            Need API Access?{' '}
            <a className="text-indigo-600" href="/contact">
              {' '}
              Contact us{' '}
            </a>{' '}
            for a custom quote{' '}
          </p>
        </div>

        {!hideTeams && (
          <div className="flex items-center flex-col mt-4 sm:m-4">
            <div className="max-w-2xl bg-indigo-50 mt-0 p-4 shadow-sm rounded-lg">
              <div className="text-zinc-600 text-center sm:text-xl max-w-2xl text-lg">
                You're in good company. Over{' '}
                <b className="text-zinc-500 text-xl">512,283</b> SnapLuna
                interiors created for top realtors and designers
              </div>
              <img
                src="/brands3.png"
                className="mt-1"
                alt="Multiple Large Companies use SnapLuna"
              ></img>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
